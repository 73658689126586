<template>
  <div ref="googleMap" :style="{ width, height }"></div>
</template>

<script>
import { Loader } from "@googlemaps/js-api-loader";
import { mapActions } from "vuex";
import { app } from "@/config/globals";

export default {
  data() {
    return {
      marker: null,
      map: null,
      mapOptions: {
        zoom: 17,
        mapTypeControl: false,
        streetViewControl: false
      }
    };
  },
  mounted() {
    this.initMap();
  },
  methods: {
    ...mapActions("business", ["setLocationLatLng", "changeFormBusinessData"]),
    async initMap() {
      // :: init Loader
      const loader = new Loader({
        apiKey: app.maps_api_key,
        version: "weekly",
        libraries: ["places"]
      });
      // :: Map container
      const mapContainer = this.$refs.googleMap;
      // eslint-disable-next-line
      if (typeof google === "object" && typeof google.maps === "object") {
        // eslint-disable-next-line
        this.map = new google.maps.Map(mapContainer, this.mapOptions);
        if (this.myLatlng) this.printMarkerLocation();
      } else {
        await loader.load();
        // eslint-disable-next-line
        this.map = new google.maps.Map(mapContainer, this.mapOptions);
        if (this.myLatlng) this.printMarkerLocation();
      }
    },
    printMarkerLocation() {
      if (this.marker) {
        this.marker.setMap(null);
        // eslint-disable-next-line
        google.maps.event.clearListeners(this.marker, "dragend");
        this.marker = null;
      }

      // eslint-disable-next-line
      const pointMap = new google.maps.LatLng(
        this.myLatlng.lat,
        this.myLatlng.lng
      );
      this.map.setCenter(pointMap);
      // eslint-disable-next-line
      this.marker = new google.maps.Marker({
        position: pointMap,
        map: this.map,
        draggable: true, // eslint-disable-next-line
        animation: google.maps.Animation.DROP,
        icon:
          "https://firebasestorage.googleapis.com/v0/b/smart-conecta.appspot.com/o/map_ssencial.png?alt=media&token=9eab7445-c732-4d9a-959e-ac299289dec2",
        title: "Business point"
      });
      // eslint-disable-next-line
      google.maps.event.addListener(this.marker, "dragend", () => {
        this.marker.setAnimation(4); // fall
        const lat = this.marker.position.lat();
        const lng = this.marker.position.lng();
        if (this.istSetupCustomer)
          this.changeFormBusinessData({
            key: "map_point",
            value: { lat, lng }
          });
        else this.setLocationLatLng({ lat, lng });
      });
    }
  },
  watch: {
    myLatlng(newLocation) {
      if (newLocation) this.printMarkerLocation();
    }
  },
  props: {
    height: {
      type: String,
      default: "300px"
    },
    width: {
      type: String,
      default: "100%"
    },
    myLatlng: {
      type: Object,
      default: null
    },
    istSetupCustomer: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style></style>
